<template>
    <div class="page-container">
        <div class="box-container">
            <div class="box">
                <div class="box-title">
                    <p>{{ $t('certificates.generate_certificate') }}</p>
                </div>
                <div class="box-form">
                    <Form class="form" @submit="generateCertificate" :disabled="is_generating">
                        <FormInputText v-model="$v.certificate.name.$model" identifier="name"
                                       :label="$t('certificates.name_and_surname')" :disabled="is_generating"
                                       :has-error="$v.certificate.name.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.certificate.name.required">
                                    {{ $t('validation.x_is_required', {x: $t('certificates.name_and_surname')}) }}
                                </p>
                            </template>
                        </FormInputText>

                        <FormInputText v-model="$v.certificate.course_name.$model" identifier="course_name"
                                       :label="$t('certificates.course_name')" :disabled="is_generating"
                                       :has-error="$v.certificate.course_name.$error" autocomplete="off" :use-textarea="true" :large-textarea="true">
                            <template v-slot:errors>
                                <p v-if="!$v.certificate.course_name.required">
                                    {{ $t('validation.x_is_required', {x: $t('certificates.course_name')}) }}
                                </p>
                            </template>
                        </FormInputText>

                        <FormInputText v-model="$v.certificate.company_name.$model" identifier="company_name"
                                       :label="$t('certificates.company_name')" :disabled="is_generating"
                                       :has-error="$v.certificate.company_name.$error" :use-textarea="true" :large-textarea="true">
                            <template v-slot:errors>
                                <p v-if="!$v.certificate.company_name.required">
                                    {{ $t('validation.x_is_required', {x: $t('certificates.company_name')}) }}
                                </p>
                            </template>
                        </FormInputText>

                        <FormInputDateTime v-model="$v.certificate.date.$model" identifier="date"
                                           :label="$t('certificates.date')" :disabled="is_generating"
                                           position="top" :onlyDate="true" formatted="Do MMMM YYYY"
                                           :has-error="$v.certificate.date.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.certificate.date.required">
                                    {{ $t('validation.x_is_required', {x: $t('certificates.date')}) }}
                                </p>
                            </template>
                        </FormInputDateTime>


                        <Button type="submit" className="--primary --small ml-auto mt-4"
                                :class="{spinner: is_generating}">
                            {{ $t('certificates.generate') }}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
        <vue-html2pdf
            :float-layout="true"
            :show-layout="false"
            :enable-download="true"
            :filename="`${certificate.name} Certificate`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @beforeDownload="beforeDownload($event)"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf">
            <section slot="pdf-content" class="pdf-content">
                <div class="printable-certificate">
                    <div class="printable-container">
                        <div class="stripes-container">
                            <div class="long-stripe"></div>
                            <div class="short-stripe orange"></div>
                            <div class="short-stripe red"></div>
                            <div class="short-stripe green"></div>
                            <div class="short-stripe blue"></div>
                        </div>
                        <div class="info-container" v-if="certificate">
                            <div class="logo-container">
                                <img src="../../assets/logo-no-text.png"/>
                                <p>Foundation for Social Welfare Services</p>
                                <p>Here for you</p>
                            </div>
                            <div class="subtitle-container collaboration-container" v-if="certificate.company_name">
                                <p>In collaboration with</p>
                                <p v-html="parsedText(certificate.company_name)"/>
                            </div>
                            <div class="certificate-of-attendance-container">
                                <p>CERTIFICATE</p>
                                <p>of Attendance</p>

                            </div>
                            <div class="subtitle-container" v-if="certificate.name">
                                <p>This certificate is presented to</p>
                                <p>{{certificate.name}}</p>
                            </div>
                            <div class="course-covered-container" v-if="certificate.course_name">
                                <p>S.A.F.E Programme topic/s covered:</p>
                                <p v-html="parsedText(certificate.course_name)"/>
                            </div>
                            <div class="date-and-signature-container" v-if="certificate.date">
                                <div class="date-container">
                                    <p>{{$moment(certificate.date).format('Do MMMM YYYY')}}</p>
                                    <p>Date</p>
                                </div>
                                <div class="signature-container">
                                    <img src="../../assets/pdf-signature.png">
                                    <p>Charles Scerri</p>
                                    <p>Sedqa Operations Director</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import {email, required} from "vuelidate/lib/validators";
import FormInputText from "../../components/form/FormInputText";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import Form from "../../components/form/Form";
import VueHtml2pdf from 'vue-html2pdf'


export default {
    name: "certificates-index-page",
    components: {FormInputDateTime, FormInputText, ConfirmModal, Select, Button, Search, Headbar, Form, VueHtml2pdf},
    data: function () {
        return {
            certificate: {
                name: null,
                course_name: null,
                company_name: null,
                date: null,
            },
            is_generating: false,
        }
    },
    validations: {
        certificate: {
            name: {required},
            course_name: {required},
            company_name: {},
            date: {required},
        }
    },
    methods: {
        parsedText(text) {
            return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
        async generateCertificate() {
            this.$v.certificate.$touch();
            if (this.$v.certificate.$anyError || this.is_generating) return;

            this.is_generating = true;
            await this.$refs.html2Pdf.generatePdf()
        },
        async beforeDownload() {
            this.is_generating = true;
        },
        async hasDownloaded() {
            this.is_generating = false;
        },
    },
    mounted() {

    },
    head() {
        return {
            title: {
                inner: this.$t('nav.certificates')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.pdf-content {
    .printable-certificate {
        @apply w-full flex flex-row;
        height: 1122px;

        .printable-container {
            @apply w-full h-full flex flex-row;

            .stripes-container {
                @apply h-full flex flex-col bg-black;
                width: 1%;

                .long-stripe {
                    @apply bg-certificate-grey w-full;
                    height: 50%;
                }

                .short-stripe {
                    @apply w-full;
                    height: 12.5%;

                    &.orange {
                        @apply bg-certificate-orange;
                    }

                    &.red {
                        @apply bg-certificate-red;
                    }

                    &.green {
                        @apply bg-certificate-green;
                    }

                    &.blue {
                        @apply bg-certificate-blue;
                    }
                }
            }

            .info-container {
                @apply h-full w-full flex flex-col bg-white gap-y-14 pt-20 pb-12;
                width: 99%;
                background-image: url('../../../src/assets/certificate-bg.png');
                background-repeat: no-repeat;
                background-size: contain;
                font-family: 'Harmonia' !important;

                .logo-container {
                    @apply flex flex-col ml-auto mr-4 pr-16;

                    img {
                        @apply h-40 mb-4 mx-auto;
                    }

                    p {
                        @apply text-certificate-text-grey;
                        font-family: 'Harmonia' !important;

                        &:nth-of-type(1) {
                            @apply font-bold text-lg;
                        }

                        &:nth-of-type(2) {
                            @apply mx-auto text-sm;
                        }
                    }
                }

                .subtitle-container {
                    @apply flex flex-col ml-auto pr-16;

                    &.collaboration-container {
                        @apply mr-12;
                    }

                    p {
                        @apply text-certificate-text-grey mr-auto text-lg;
                        font-family: 'Harmonia' !important;

                        &:nth-of-type(2) {
                            @apply text-black;
                        }
                    }
                }

                .certificate-of-attendance-container {
                    @apply flex flex-col ml-auto pr-16 -mt-2;

                    p {
                        @apply text-certificate-text-grey mr-auto;

                        &:nth-of-type(1) {
                            @apply font-bold text-6xl;
                            font-family: 'Harmonia-Bold' !important;
                        }

                        &:nth-of-type(2) {
                            @apply text-4xl ml-auto mr-0 italic;
                            font-family: 'Harmonia-Italic' !important;
                        }
                    }
                }

                .course-covered-container {
                    @apply flex flex-col mx-auto gap-y-3;

                    p {
                        @apply text-center;
                        font-family: 'Harmonia' !important;

                        &:nth-of-type(2) {
                            @apply mx-auto font-bold;
                        }
                    }
                }

                .date-and-signature-container {
                    @apply flex flex-row justify-between pl-8 mt-auto pr-16;

                    .date-container {
                        @apply flex flex-col mt-auto mb-6;

                        p {
                            font-family: 'Harmonia' !important;
                            &:nth-of-type(1) {
                                @apply border-b border-certificate-text-grey font-bold px-2 pb-2;
                            }

                            &:nth-of-type(2) {
                                @apply text-certificate-text-grey;
                            }
                        }
                    }
                }

                .signature-container {
                    @apply flex flex-col;

                    img {
                        @apply h-24 pb-2 mx-auto border-certificate-text-grey;
                    }

                    p {
                        font-family: 'Harmonia' !important;

                        &:nth-of-type(1) {
                            @apply border-t border-certificate-text-grey font-bold px-2 ml-auto pl-24;
                        }

                        &:nth-of-type(2) {
                            @apply text-certificate-text-grey;
                        }
                    }
                }
            }
        }
    }
}

.page-container {
    @apply w-full h-full;

    .box-container {
        @apply flex flex-row w-full h-full py-8;

        .box {
            @apply m-auto bg-white rounded-lg w-full mx-8 max-w-xl text-grey-darkest;
            box-shadow: 5px 0px 10px #0000001A;

            @screen md {
                @apply w-1/2 mx-auto;
            }

            .box-title {
                @apply flex flex-row border-b border-grey-light py-4;

                p {
                    @apply font-bold ml-5;
                }
            }

            .box-form {
                @apply p-5;

                .form {
                    @apply flex flex-col;
                }
            }
        }
    }
}
</style>
