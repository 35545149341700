<template>
    <div class="login-page-container darken">
        <div class="content">
            <img src="../assets/logo.png" class="logo " alt="logo"/>

            <Form v-if="screen === 'login'" @submit="login" :disabled="is_processing" class="form-container login-form">
                <div class="input-group input-group--input" :class="{error: $v.credentials.email.$error}">
                    <div class="input-wrapper">
                        <input v-model="$v.credentials.email.$model" type="text" :placeholder="$t('auth.email')"
                               :disabled="is_processing" autocomplete="username">
                        <div v-if="$v.credentials.email.$error" class="errors">
                            <p v-if="!$v.credentials.email.required">
                                {{ $t('auth.email_is_required') }}
                            </p>
                            <p v-else-if="!$v.credentials.email.email">
                                {{ $t('auth.enter_valid_email') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="input-group input-group--password" :class="{error: $v.credentials.password.$error}">
                    <div class="input-wrapper">
                        <div class="password-field">
                            <input v-model="$v.credentials.password.$model" :placeholder="$t('auth.password')"
                                   :type="show_password ? 'text' : 'password'" :disabled="is_processing"
                                   autocomplete="current-password">
                            <button type="button" @click="show_password = false" v-if="show_password">
                                <font-awesome-icon :icon="['far','eye-slash']"/>
                            </button>
                            <button type="button" @click="show_password = true" v-else>
                                <font-awesome-icon :icon="['far','eye']"/>
                            </button>
                        </div>
                        <div v-if="$v.credentials.password.$error" class="errors">
                            <p v-if="!$v.credentials.password.required">
                                {{ $t('auth.password_is_required') }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="submit-row">
                    <Button :disabled="is_processing" className="--primary --small --wide" :class="{'spinner-black': is_processing}"
                            type="submit">
                        <span>{{ $t('nav.login') }}</span>
                    </Button>

                    <a @click="screen = 'forgot'" class="forgot">
                        {{ $t('auth.forgot_password_q') }}
                    </a>
                </div>
            </Form>
            <div v-else-if="screen === 'forgot'" class="forgot-wrapper">
                <button class="back" @click="screen = 'login'">
                    <font-awesome-icon :icon="['fal','long-arrow-left']"/>
                </button>
                <Form @submit="forgot" :disabled="is_processing" class="form-container forgot-form">

                    <p>{{ $t('auth.forgot_password_text') }}</p>

                    <div class="input-group input-group--input" :class="{error: $v.credentials.email.$error}">
                        <div class="input-wrapper">
                            <input v-model="$v.credentials.email.$model" type="text" :placeholder="$t('auth.email')"
                                   :disabled="is_processing" autocomplete="username">
                            <div v-if="$v.credentials.email.$error" class="errors">
                                <p v-if="!$v.credentials.email.required">
                                    {{ $t('auth.email_is_required') }}
                                </p>
                                <p v-else-if="!$v.credentials.email.email">
                                    {{ $t('auth.enter_valid_email') }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="submit-row">
                        <Button :disabled="is_processing" className="--primary mt-4"
                                :class="{'spinner-black': is_processing}" type="submit">
                            {{ $t('auth.reset_password') }}
                        </Button>
                    </div>
                </Form>
            </div>
            <Form v-else-if="screen === 'reset'" class="reset-form">
                <p>{{ $t('auth.email_sent') }}</p>

                <font-awesome-icon :icon="['fal','check-circle']"/>
                <div class="submit-row">
                    <Button className="--primary" :onclick="() => this.screen = 'login'">
                        {{ $t('nav.login') }}
                    </Button>
                </div>
            </Form>
        </div>
        <div class="engineered-by-arkafort">
            <p>Engineered by</p>
            <img src="../assets/white-logo.svg" alt="Arkafort"/>
        </div>
    </div>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'
import Button from "../components/Button";
import Form from "../components/form/Form";
import FormInputText from "@/components/form/FormInputText";

export default {
    name: "login-page",
    components: {FormInputText, Form, Button},
    data() {
        return {
            credentials: {
                email: '',
                password: '',
            },
            show_password: false,
            is_processing: false,
            screen: 'login',
        }
    },
    validations: {
        credentials: {
            email: {
                required,
                email,
            },
            password: {
                required,
            }
        }
    },
    methods: {
        login() {
            this.$v.credentials.$touch();
            if (this.$v.credentials.$anyError || this.is_processing)
                return;

            this.is_processing = true;
            this.$axios.post('auth/login', {
                email: this.$v.credentials.email.$model,
                password: this.$v.credentials.password.$model,
            }).then(async ({data}) => {
                this.$store.commit("SET_TOKEN", data.data.token);
                await this.$store.dispatch('getUser');

                this.is_processing = false;
                if (this.$store.getters.hasRole('organisation')) {
                    this.$router.push('bookings');
                } else {
                    this.$router.push('/');
                }
            }).catch(e => {
                this.is_processing = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('auth.failed_login')),
                    type: 'error',
                });
            });
        },
        forgot() {
            this.$v.credentials.email.$touch();
            if (this.$v.credentials.email.$anyError || this.is_processing)
                return;

            this.is_processing = true;
            this.$axios.post('auth/password/reset/request', {
                email: this.$v.credentials.email.$model,
            }).then(async ({data}) => {
                this.screen = 'reset';

                this.is_processing = false;
            }).catch(e => {
                this.is_processing = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('auth.failed_reset')),
                    type: 'error',
                });
            });
        }
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.login')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.login-page-container {
    @apply bg-cover bg-center bg-no-repeat bg-primary min-h-screen w-full flex flex-col justify-center items-center;

    //&.darken {
    //    position: relative;
    //
    //    &:after {
    //        content: '';
    //        position: absolute;
    //        top: 0;
    //        bottom: 0;
    //        left: 0;
    //        right: 0;
    //        display: block;
    //        background-color: rgba(0, 0, 0, 0.38);
    //    }
    //}

    .content {
        @apply px-10 py-8 rounded-lg flex flex-col max-w-full bg-white;
        width: 90vw;
        //background-color: rgba(0, 0, 0, 0.65);
        z-index: 1;

        @screen sm {
            width: 478px;
        }

        .logo {
            @apply w-auto mb-8 object-contain h-20;
        }

        .form-container {
            @apply bg-white;

            & > .input-group {
                margin-bottom: 1rem !important;

                input {
                    color: theme('colors.black') !important;
                    height: 50px;
                }

                .password-field {
                    height: 50px;

                    & > button {
                        @apply mt-1;
                    }
                }
            }

            .submit-row {
                @apply flex flex-row justify-between items-center;
                margin-top: 0rem !important;

                a.forgot {
                    @apply text-grey-darkest text-sm no-underline cursor-pointer;
                }
            }
        }

        .login-form {
            .input-group {
                @apply flex flex-col items-start mb-6;

                & > label {
                    @apply text-sm block text-grey-darkest font-bold mb-2 ml-4;

                    small {
                        @apply text-xs;
                    }
                }

                & > .input-wrapper, &.input-group-with-button > .input-button-wrapper > .input-wrapper {
                    @apply w-full flex flex-col;
                }

                .input-wrapper {
                    input, textarea {
                        @apply bg-transparent text-black border-2 border-grey-dark p-4;

                        &:focus {
                            @apply border-primary outline-none;
                        }
                    }
                }

                &.error {
                    input, textarea, .password-field {
                        border-color: theme('colors.negative') !important;
                    }

                    .multiselect {
                        border-color: theme('colors.negative') !important;

                        &.multiselect--active {
                            border-color: theme('colors.primary') !important;
                        }
                    }
                }

                &.input-group--input {
                    input, textarea {
                        @apply bg-transparent text-black border-2 border-grey rounded text-base w-full p-4 appearance-none;

                        &:focus {
                            @apply border-primary outline-none;
                        }
                    }

                    ::-webkit-input-placeholder { /* Edge */
                        color: theme('colors.black') !important;
                        font-weight: normal;
                        opacity: 1 !important;
                        font-size: 14px !important;
                    }

                    :-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: theme('colors.black') !important;
                        font-weight: normal;
                        opacity: 1 !important;
                        font-size: 14px !important;
                    }

                    ::placeholder {
                        color: theme('colors.black') !important;
                        font-weight: normal;
                        opacity: 1 !important;
                        font-size: 14px !important;
                    }
                }

                &.input-group--password {
                    & > .input-wrapper {
                        @apply w-full flex flex-col border-0 rounded;

                        & > .password-field {
                            @apply rounded bg-transparent border-2 border-grey w-full p-0 flex flex-row items-center justify-between;

                            input {
                                @apply border-0 bg-transparent h-12 text-black text-base w-full appearance-none flex-1;
                                height: 99%;
                            }


                            button {
                                @apply p-0 ml-4 mr-4;

                                &:active, &:focus {
                                    @apply outline-none;
                                }

                                svg {
                                    @apply cursor-pointer text-grey text-lg ml-4;
                                }
                            }

                            &:focus-within {
                                @apply border-primary;
                            }
                        }
                    }

                    ::-webkit-input-placeholder { /* Edge */
                        color: theme('colors.black') !important;
                        font-weight: normal;
                        opacity: 1 !important;
                        font-size: 14px !important;
                    }

                    :-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: theme('colors.black') !important;
                        font-weight: normal;
                        opacity: 1 !important;
                        font-size: 14px !important;
                    }

                    ::placeholder {
                        color: theme('colors.black') !important;
                        font-weight: normal;
                        opacity: 1 !important;
                        font-size: 14px !important;
                    }
                }
            }

            .errors {
                @apply mt-3 mb-1;

                p {
                    @apply text-negative italic text-sm py-1 ml-4;
                }
            }

            button[type="submit"] {
                @apply self-start;
            }

            .submit-row {
                @apply flex flex-row flex-wrap mt-3;

                button[type=button] {
                    @apply ml-2;
                }
            }
        }

        .forgot-wrapper {

            button.back {
                @apply absolute top-0 left-0;

                svg {
                    @apply text-white text-3xl mt-11 ml-10;
                }

                &:active, &:focus {
                    @apply outline-none;
                }
            }

            .forgot-form {
                @apply flex flex-col items-center;

                & > p {
                    @apply text-black font-normal leading-6 text-base text-center -mt-3 mb-2;
                    max-width: 20rem;
                }

                .input-group {
                    @apply flex flex-col items-start my-6 w-full;

                    & > label {
                        @apply text-sm block text-black font-bold mb-2 ml-4;

                        small {
                            @apply text-xs;
                        }
                    }

                    & > .input-wrapper, &.input-group-with-button > .input-button-wrapper > .input-wrapper {
                        @apply w-full flex flex-col;
                    }

                    .input-wrapper {
                        input, textarea {
                            @apply bg-transparent text-black border-2 border-grey p-4;

                            &:focus {
                                @apply border-grey outline-none;
                            }
                        }
                    }

                    &.error {
                        input, textarea, .password-field {
                            border-color: theme('colors.negative') !important;
                        }
                    }

                    &.input-group--input {
                        input, textarea {
                            @apply bg-transparent text-black border-2 border-grey rounded text-base w-full p-4 appearance-none;

                            &:focus {
                                @apply border-grey outline-none;
                            }
                        }

                        ::-webkit-input-placeholder { /* Edge */
                            color: theme('colors.black') !important;
                            font-weight: normal;
                            opacity: 1 !important;
                            font-size: 14px !important;
                        }

                        :-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: theme('colors.black') !important;
                            font-weight: normal;
                            opacity: 1 !important;
                            font-size: 14px !important;
                        }

                        ::placeholder {
                            color: theme('colors.black') !important;
                            font-weight: normal;
                            opacity: 1 !important;
                            font-size: 14px !important;
                        }
                    }

                    .errors {
                        @apply mt-3 mb-1;

                        p {
                            @apply text-negative italic text-sm py-1 ml-4;
                        }
                    }

                    .submit-row {
                        @apply flex flex-row justify-between items-center w-full;

                        button {
                            @apply w-full;
                        }
                    }
                }
            }
        }


        .reset-form {
            @apply flex flex-col items-center mb-7;

            & > p {
                @apply text-black font-normal text-base text-center max-w-92 mt-3;
            }

            svg {
                @apply text-primary my-8;
                font-size: 5.65rem;
            }

            .submit-row {
                @apply flex flex-row justify-between items-center w-full;

                button {
                    @apply mx-auto cursor-pointer;
                }
            }
        }
    }

    .engineered-by-arkafort {
        @apply flex flex-row absolute items-center;
        top: 92.5%;

        & > p {
            @apply text-sm text-white;
        }

        & > img {
            @apply ml-4 h-5;
        }
    }
}
</style>
