<template>
    <aside class="sidebar-container">
        <router-link :to="{name: 'certificates-index'}" class="nav-item">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'file-certificate']"/>
            </div>
            <p>{{$t('nav.certificates')}}</p>
        </router-link>

        <router-link :to="{name: 'roles-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read roles')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{$t('nav.roles_permissions')}}</p>
        </router-link>

        <router-link :to="{name: 'users-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read users')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{$t('nav.users')}}</p>
        </router-link>
    </aside>
</template>

<script>
    export default {
        name: "Sidebar"
    }
</script>

<style lang="scss" scoped>
    .sidebar-container {
        @apply h-full flex flex-col bg-white overflow-y-auto hidden py-8;

        box-shadow: 5px 0px 10px #0000001A;
        width: 130px;
        grid-area: sidebar;

        @screen md {
            @apply flex;
        }

        @screen 3xl {
            @apply py-8;
        }

        .nav-item {
            @apply flex flex-col items-center mb-8 px-4;

            .icon-container {
                @apply p-2 rounded-xl border-2 border-grey-light flex flex-col items-center justify-center;
                height: 40px;
                width: 40px;

                svg {
                    @apply text-black w-auto;
                    height: 18px;
                }
            }

            p {
                @apply text-black text-xs font-bold mt-2 text-center text-grey-darkest;

                @screen 3xl {
                    @apply text-sm mt-4;
                }
            }

            &:hover, &:focus {
                .icon-container {
                    @apply border-primary;
                }
            }

            &.router-link-active {
                .icon-container {
                    @apply bg-primary border-primary;

                    svg {
                        @apply text-white;
                    }
                }
            }
        }
    }
</style>